<!--suppress HtmlUnknownAttribute -->
<script>
    import {svgContents} from "./SvgIcon.js";

    export let name,
        viewBox = "0 0 32 32",
        width = null,
        height = null,
        fill = "currentcolor",
        background = null,
        border = null,
        style = null

</script>

<svg xmlns="http://www.w3.org/2000/svg" {width} {height} {viewBox}
     style="fill-rule:evenodd; {style}"
     {fill}
     style:background
     style:border
     on:mouseover
     on:focus
     on:mouseout
     on:blur
     on:click

     tabindex="-1"
>

    {@html svgContents[name]}

</svg>


<!--suppress CssUnresolvedCustomProperty -->
<style>

    svg {
        display: block;
        transition: fill 100ms;
    }

    :focus {
        outline: none;
    }

    svg:hover {

    }

</style>